/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-08-26",
    versionChannel: "nightly",
    buildDate: "2024-08-26T00:05:52.872Z",
    commitHash: "c869505e88f792b1b6691cd8d6f0185bde4c21b9",
};
